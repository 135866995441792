import React, {useEffect, useState} from "react";
import axios from 'utility/customAxios';
import {useSelector} from "react-redux";
import timerange from "../../store/reducers/timerange";
import SummaryTable from "./SummaryTable";
import {K8sPodDetails} from "./model";
import {MetoroMetricsChart, MetricType} from "../../pages/MetricsTest";
import {Tooltip, TooltipContent, TooltipTrigger} from "../ui/tooltip";
import {ChevronDown, InfoIcon} from "lucide-react";
import {useSearchParams} from "react-router-dom";
import {TimeRange} from "../../types/time";
import {useDebouncedCallback} from "use-debounce";
import {RateFunctionType} from "../Dashboarding/widgets/MetricSelector";
import {cn} from "../ui/lib/utils";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from "../ui/dropdown-menu";
import {capitalize} from "@mui/material";
import {PodsTable} from "./PodsTable";
import {ChartType} from "../Charts/MetoroChart";
import {MultiMetoroMetricsChart} from "../Charts/MultiMetoroMetricsCharts";
import {formatServiceNameFilterValues} from "../Filter/Filter";


export interface K8sSummaryResponseV2 {
    k8sResourceSummary: K8sResourceSummary[];
}

export interface K8sResourceSummary {
    environment: string;
    kind: string;
    resourceYaml: string;
}

export interface GetPodsResponse {
    pods: K8sPodDetails[]
    actualLength: number
    isResultLimited: boolean
}

// type GetPodsSummary struct {
//     States []PodSummary `json:"states"`
// }
//
// type PodSummary struct {
//     Bucket     time.Time `json:"bucket"`
//     Running    uint64    `json:"running"`
//     Waiting    uint64    `json:"waiting"`
//     Terminated uint64    `json:"terminated"`
// }

function capitalizeFirstLetter(val: string) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

interface GetPodsCountResponse {
    summaries: PodSummary[]
}

interface PodSummary {
    bucket: number
    running: number
    waiting: number
    terminated: number
}

export interface PodState {
    startTime: number;
    endTime: number;
    timestamp: number;
    phase: string;
    reason: string;
    exitCode: string;
    version: string;
}

interface KubernetesViewProps {
    serviceName: string;
}

export function MetricsPanel(props: {
    title: string
    tooltipText: string
    serviceName?: string
    nodeName?: string
    groupBy?: string[]
    groupByForNetworkCharts?: string[]
    filters?: Map<string, string[]>
    excludeFilters?: Map<string, string[]>
}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [environment, setEnvironment] = useState<string[]>([]);
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const [filters, setFilters] = useState<Map<string, string[]>>(
        props.filters || new Map<string, string[]>()
    );
    const [aggregate, setAggregate] = useState<string>("max");
    const [splits, setSplits] = useState<string[]>([]);
    const [networkSplits, setNetworkSplits] = useState<string[]>([]);

    const groupByToUse = splits
    const groupByForNetworkCharts = networkSplits

    // We reuse this component in Infrastructure view and it should be default to Container split there.
    useEffect(() => {
        if (props.nodeName !== undefined) {
            setSearchParams((prev) => {
                const aggr = prev.get("aggregate");
                const splits = prev.get("metricPanelsplits");
                const networkSplits = prev.get("networkSplits");
                if (aggr === null) {
                    prev.set("aggregate", "max");
                }
                if (splits === null) {
                    prev.set("metricPanelsplits", "pod_name");
                }
                if (networkSplits === null) {
                    prev.set("networkSplits", "pod_name");
                }
                return prev;
            })
        }
    }, []);

    useEffect(() => {
        let aggregate = searchParams.get("aggregate");
        if (aggregate) {
            setAggregate(aggregate);
        }
    }, [searchParams]);

    useEffect(() => {
        let splits = searchParams.get("metricPanelsplits");
        if (splits) {
            setSplits(splits.split(","));
        } else {
            setSplits([]);
        }
    }, [searchParams]);

    useEffect(() => {
        let networkSplits = searchParams.get("networkSplits");
        if (networkSplits) {
            setNetworkSplits(networkSplits.split(","));
        } else {
            setNetworkSplits([]);
        }
    }, [searchParams]);

    useEffect(() => {
        let newEnv = searchParams.get("environment")
        if (JSON.stringify(environment) === JSON.stringify([newEnv])) {
            return
        }
        if (newEnv) {
            setEnvironment([newEnv]);
        } else {
            setEnvironment([]);
        }
    }, [searchParams]);

    useEffect(() => {
        // Short circuit if the filter is already set
        if ((filters.get("service_name")?.[0] === props.serviceName && filters.get("kubernetes.io/hostname")?.[0] === props.nodeName)
            && JSON.stringify(environment) === JSON.stringify(filters.get("environment"))) {
            return
        }
        let filtersToUse = new Map<string, string[]>(filters);
        if (props.serviceName !== undefined) {
            filtersToUse.set("service_name", [props.serviceName]);
        } else if (props.nodeName !== undefined) {
            filtersToUse.set("kubernetes.io/hostname", [props.nodeName]);
        }
        filtersToUse.set("environment", [...environment]);
        setFilters(filtersToUse);
    }, [props.serviceName, props.nodeName, props.filters, environment]);

    const [startTime, setStartTime] = useState<number>(Math.floor((timeRange.getStartEnd())[0].getTime() / 1000));
    const [endTime, setEndTime] = useState<number>(Math.floor((timeRange.getStartEnd())[1].getTime() / 1000));
    useEffect(() => {
        setStartTime(Math.floor((timeRange.getStartEnd())[0].getTime() / 1000));
        setEndTime(Math.floor((timeRange.getStartEnd())[1].getTime() / 1000));
    }, [timeRange]);

    // HACK HACK
    if (props.nodeName !== undefined && !splits.includes("pod_name")) {
        return <div></div>
    }

    return <div className={"bg-backgroundmedium border p-4 relative"}>
        <div className={"flex justify-between"}>
            <Tooltip>
                <TooltipTrigger>
                    <div className={"flex gap-2 mb-4"}>
                        <div className={"text-lg text-textmedium flex flex-col justify-center"}>
                            {props.title}
                        </div>
                        <div className={"flex flex-col justify-center"}>
                            <InfoIcon className={"h-4 w-4 text-primary"}/>
                        </div>
                    </div>
                </TooltipTrigger>
                <TooltipContent className={"border bg-backgroundmedium text-textmedium p-2"}>
                    {props.tooltipText}
                </TooltipContent>
            </Tooltip>
            <div>
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <div
                            className={"flex rounded border border-border bg-backgroundmedium justify-center items-center hover:cursor-pointer hover:border-primary"}>
                            <div
                                className={"text-textlight px-2 text border border-secondary bg-secondarytransparenter"}>Aggregate
                            </div>
                            <div className={"border-l flex bg-backgroundlight justify-center items-center px-2 h-full"}>
                                <div
                                    className={"text-textmedium bg-backgroundlight pr-2"}>
                                    {splits.length > 0 && splits.includes("pod_name") ? "Pod" : splits.length > 0 ? "Container" : capitalize(aggregate)}
                                </div>
                                <ChevronDown
                                    className={"bg-backgroundlight text-textmedium h-4 w-4"}/>
                            </div>
                        </div>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-56 bg-backgroundmedium rounded">
                        <DropdownMenuLabel className={"text-textmedium"}>Aggregate</DropdownMenuLabel>
                        <DropdownMenuSeparator className={"bg-border"}/>
                        <DropdownMenuGroup>
                            <DropdownMenuItem key={"all"}
                                              className={"text-textmedium hover:bg-backgroundlight hover:text-primary hover:cursor-pointer"}
                                              onClick={() => {
                                                  setSearchParams((searchParams) => {
                                                      searchParams.set("aggregate", "max")
                                                      return searchParams
                                                  })
                                                  if (splits.length > 0) {
                                                      setSearchParams((searchParams) => {
                                                          searchParams.delete("metricPanelsplits")
                                                          return searchParams
                                                      })
                                                  }
                                                  if (networkSplits.length > 0) {
                                                      setSearchParams((searchParams) => {
                                                          searchParams.delete("networkSplits")
                                                          return searchParams
                                                      })
                                                  }
                                              }}>
                                Service
                            </DropdownMenuItem>
                            <DropdownMenuItem key={"all"}
                                              className={"text-textmedium hover:bg-backgroundlight hover:text-primary hover:cursor-pointer"}
                                              onClick={() => {
                                                  setSearchParams((searchParams) => {
                                                      searchParams.set("aggregate", "max")
                                                      return searchParams
                                                  })
                                                  setSearchParams((searchParams) => {
                                                      searchParams.set("metricPanelsplits", "container_id")
                                                      return searchParams
                                                  })
                                                  setSearchParams((searchParams) => {
                                                      searchParams.set("networkSplits", "pod_name")
                                                      return searchParams
                                                  })
                                              }}>
                                Container
                            </DropdownMenuItem>
                            <DropdownMenuItem key={"all"}
                                              className={"text-textmedium hover:bg-backgroundlight hover:text-primary hover:cursor-pointer"}
                                              onClick={() => {
                                                  setSearchParams((searchParams) => {
                                                      searchParams.set("aggregate", "max")
                                                      return searchParams
                                                  })
                                                  setSearchParams((searchParams) => {
                                                      searchParams.set("metricPanelsplits", "pod_name")
                                                      return searchParams
                                                  })
                                                  setSearchParams((searchParams) => {
                                                      searchParams.set("networkSplits", "pod_name")
                                                      return searchParams
                                                  })
                                              }}>
                                Pod
                            </DropdownMenuItem>
                        </DropdownMenuGroup>
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>
        </div>
        {groupByToUse.length == 0 &&
        <div className={"grid-cols-2 grid gap-4 rounded text-textlight overflow-y-auto relative"}>

            <MultiMetoroMetricsChart
                hideOnNoData={false}
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                startTime={startTime}
                endTime={endTime}
                metricSpecifiers={[
                    {
                        metricType: MetricType.Metric,
                        metricName: "container_resources_cpu_usage_seconds_total",
                        splits: groupByToUse,
                        filters: filters,
                        excludeFilters: props.excludeFilters,
                        functions: [{
                            id: "1",
                            functionType: RateFunctionType.PerSecond
                        }],
                        aggregation: "sum",
                        visualization: {
                            lineColor: "#4bc0c0",
                            lineDotColor: "#4bc0c033",
                            displayName:  "Total CPU Usage",
                        }
                    },
                    {
                        metricType: MetricType.Metric,
                        metricName: "container_resources_cpu_limit_cores",
                        splits: [],
                        filters: filters,
                        excludeFilters: props.excludeFilters,
                        functions: [],
                        aggregation: "sum",
                        visualization: {
                            displayName: "CPU Limit",
                            lineDash: [10, 10],
                            lineColor: "#ff6384",
                            lineDotColor: "#ff638400",
                            lineDotSize: 0
                        }
                    }
                ]}
                type={ChartType.Line}
                title={`CPU Usage - ` + formatServiceNameFilterValues(props.serviceName!)}
                styling={{borderless: true}}
            />
            <MultiMetoroMetricsChart
                hideOnNoData={false}
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                startTime={startTime}
                endTime={endTime}
                metricSpecifiers={[
                    {
                        metricType: MetricType.Metric,
                        metricName: "container_resources_cpu_usage_seconds_total",
                        splits: groupByToUse,
                        filters: filters,
                        excludeFilters: props.excludeFilters,
                        functions: [{
                            id: "1",
                            functionType: RateFunctionType.PerSecond
                        }],
                        aggregation: "min",
                        visualization: {
                            lineColor: "#4bc0c0",
                            lineDotColor: "#4bc0c033",
                            displayName:  "Min Pod CPU Usage",
                        }
                    },
                    {
                        metricType: MetricType.Metric,
                        metricName: "container_resources_cpu_usage_seconds_total",
                        splits: groupByToUse,
                        filters: filters,
                        excludeFilters: props.excludeFilters,
                        functions: [{
                            id: "1",
                            functionType: RateFunctionType.PerSecond
                        }],
                        aggregation: "max",
                        visualization: {
                            lineColor: "#3793ff",
                            lineDotColor: "#3793ff33",
                            displayName:  "Max Pod CPU Usage",
                        }
                    },
                    {
                        metricType: MetricType.Metric,
                        metricName: "container_resources_cpu_usage_seconds_total",
                        splits: groupByToUse,
                        filters: filters,
                        excludeFilters: props.excludeFilters,
                        functions: [{
                            id: "1",
                            functionType: RateFunctionType.PerSecond
                        }],
                        aggregation: "avg",
                        visualization: {
                            lineColor: "#ffce56",
                            lineDotColor: "#ffce5633",
                            displayName:  "Avg Pod CPU Usage",
                        }
                    },
                    {
                        metricType: MetricType.Metric,
                        metricName: "container_resources_cpu_limit_cores",
                        splits: [],
                        filters: filters,
                        excludeFilters: props.excludeFilters,
                        functions: [],
                        aggregation: aggregate,
                        visualization: {
                            displayName: "CPU Limit",
                            lineDash: [10, 10],
                            lineColor: "#ff6384",
                            lineDotColor: "#ff638400",
                            lineDotSize: 0
                        }
                    }

                ]}
                type={ChartType.Line}
                title={`CPU Usage - Pods`}
                styling={{borderless: true}}
            />
            <MultiMetoroMetricsChart
                hideOnNoData={false}
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                startTime={startTime}
                endTime={endTime}
                metricSpecifiers={[
                    {
                        metricType: MetricType.Metric,
                        metricName: "container_resources_memory_rss_bytes",
                        splits: groupByToUse,
                        filters: filters,
                        excludeFilters: props.excludeFilters,
                        functions: [],
                        aggregation: "sum",
                        visualization: {
                            lineColor: "#4bc0c0",
                            lineDotColor: "#4bc0c033",
                            displayName:  "Total Memory Usage",
                        }
                    },
                    {
                        metricType: MetricType.Metric,
                        metricName: "container_resources_memory_limit_bytes",
                        splits: [],
                        filters: filters,
                        excludeFilters: props.excludeFilters,
                        functions: [],
                        aggregation: "sum",
                        visualization: {
                            displayName: "Memory Limit",
                            lineDash: [10, 10],
                            lineColor: "#ff6384",
                            lineDotColor: "#ff638400",
                            lineDotSize: 0
                        }
                    }

                ]}
                type={ChartType.Line}
                title={`Memory Usage - ` + formatServiceNameFilterValues(props.serviceName!)}
                styling={{borderless: true}}
            />
            <MultiMetoroMetricsChart
                hideOnNoData={false}
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                startTime={startTime}
                endTime={endTime}
                metricSpecifiers={[
                    {
                        metricType: MetricType.Metric,
                        metricName: "container_resources_memory_rss_bytes",
                        splits: groupByToUse,
                        filters: filters,
                        excludeFilters: props.excludeFilters,
                        functions: [],
                        aggregation: "min",
                        visualization: {
                            lineColor: "#4bc0c0",
                            lineDotColor: "#4bc0c033",
                            displayName:  "Min Pod Memory Usage",
                        }
                    },
                    {
                        metricType: MetricType.Metric,
                        metricName: "container_resources_memory_rss_bytes",
                        splits: groupByToUse,
                        filters: filters,
                        excludeFilters: props.excludeFilters,
                        functions: [],
                        aggregation: "max",
                        visualization: {
                            lineColor: "#3793ff",
                            lineDotColor: "#3793ff33",
                            displayName:  "Max Pod Memory Usage",
                        }
                    },
                    {
                        metricType: MetricType.Metric,
                        metricName: "container_resources_memory_rss_bytes",
                        splits: groupByToUse,
                        filters: filters,
                        excludeFilters: props.excludeFilters,
                        functions: [],
                        aggregation: "avg",
                        visualization: {
                            lineColor: "#ffce56",
                            lineDotColor: "#ffce5633",
                            displayName:  "Avg Memory Usage",
                        }
                    },
                    {
                        metricType: MetricType.Metric,
                        metricName: "container_resources_memory_limit_bytes",
                        splits: [],
                        filters: filters,
                        excludeFilters: props.excludeFilters,
                        functions: [],
                        aggregation: aggregate,
                        visualization: {
                            displayName: "Memory Limit",
                            lineDash: [10, 10],
                            lineColor: "#ff6384",
                            lineDotColor: "#ff638400",
                            lineDotSize: 0
                        }
                    }

                ]}
                type={ChartType.Line}
                title={`Memory Usage - Pods`}
                styling={{borderless: true}}
            />

            <MultiMetoroMetricsChart
                hideOnNoData={false}
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                startTime={startTime}
                endTime={endTime}
                metricSpecifiers={[
                    {
                        metricType: MetricType.Metric,
                        metricName: "container_net_bytes_sent_total",
                        splits: groupByToUse,
                        filters: filters,
                        excludeFilters: props.excludeFilters,
                        functions: [],
                        aggregation: "sum",
                        visualization: {
                            lineColor: "#4bc0c0",
                            lineDotColor: "#4bc0c033",
                            displayName:  "Total Network Bytes Sent",
                        }
                    },
                ]}
                type={ChartType.Line}
                title={`Network Bytes Sent - ` + formatServiceNameFilterValues(props.serviceName!)}
                styling={{borderless: true}}
            />

            <MultiMetoroMetricsChart
                hideOnNoData={false}
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                startTime={startTime}
                endTime={endTime}
                metricSpecifiers={[
                    {
                        metricType: MetricType.Metric,
                        metricName: "container_net_bytes_sent_total",
                        splits: groupByToUse,
                        filters: filters,
                        excludeFilters: props.excludeFilters,
                        functions: [],
                        aggregation: "min",
                        visualization: {
                            lineColor: "#4bc0c",
                            lineDotColor: "#4bc0c033",
                            displayName:  "Min Network Bytes Sent",
                        }
                    },
                    {
                        metricType: MetricType.Metric,
                        metricName: "container_net_bytes_sent_total",
                        splits: groupByToUse,
                        filters: filters,
                        excludeFilters: props.excludeFilters,
                        functions: [],
                        aggregation: "max",
                        visualization: {
                            lineColor: "#3793ff",
                            lineDotColor: "#3793ff33",
                            displayName:  "Max Network Bytes Sent",
                        }
                    },
                    {
                        metricType: MetricType.Metric,
                        metricName: "container_net_bytes_sent_total",
                        splits: groupByToUse,
                        filters: filters,
                        excludeFilters: props.excludeFilters,
                        functions: [],
                        aggregation: "avg",
                        visualization: {
                            lineColor: "#ffce56",
                            lineDotColor: "#ffce5633",
                            displayName:  "Avg Network Bytes Sent",
                        }
                    },
                ]}
                type={ChartType.Line}
                title={`Network Bytes Sent - Pods`}
                styling={{borderless: true}}
            />

            <MultiMetoroMetricsChart
                hideOnNoData={false}
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                startTime={startTime}
                endTime={endTime}
                metricSpecifiers={[
                    {
                        metricType: MetricType.Metric,
                        metricName: "container_net_bytes_received_total",
                        splits: groupByToUse,
                        filters: filters,
                        excludeFilters: props.excludeFilters,
                        functions: [],
                        aggregation: "sum",
                        visualization: {
                            lineColor: "#4bc0c0",
                            lineDotColor: "#4bc0c033",
                            displayName:  "Min Network Bytes Received",
                        }
                    },
                ]}
                type={ChartType.Line}
                title={`Network Bytes Received - ` + formatServiceNameFilterValues(props.serviceName!)}
                styling={{borderless: true}}
            />
            <MultiMetoroMetricsChart
                hideOnNoData={false}
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                startTime={startTime}
                endTime={endTime}
                metricSpecifiers={[
                    {
                        metricType: MetricType.Metric,
                        metricName: "container_net_bytes_received_total",
                        splits: groupByToUse,
                        filters: filters,
                        excludeFilters: props.excludeFilters,
                        functions: [],
                        aggregation: "min",
                        visualization: {
                            lineColor: "#4bc0c0",
                            lineDotColor: "#4bc0c033",
                            displayName:  "Min Network Bytes Received",
                        }
                    },
                    {
                        metricType: MetricType.Metric,
                        metricName: "container_net_bytes_received_total",
                        splits: groupByToUse,
                        filters: filters,
                        excludeFilters: props.excludeFilters,
                        functions: [],
                        aggregation: "max",
                        visualization: {
                            lineColor: "#3793ff",
                            lineDotColor: "#3793ff33",
                            displayName:  "Max Network Bytes Received",
                        }
                    },
                    {
                        metricType: MetricType.Metric,
                        metricName: "container_net_bytes_received_total",
                        splits: groupByToUse,
                        filters: filters,
                        excludeFilters: props.excludeFilters,
                        functions: [],
                        aggregation: "avg",
                        visualization: {
                            lineColor: "#ffce56",
                            lineDotColor: "#ffce5633",
                            displayName:  "Avg Network Bytes Received",
                        }
                    },
                ]}
                type={ChartType.Line}
                title={`Network Bytes Received - Pods`}
                styling={{borderless: true}}
            />
            <MultiMetoroMetricsChart
                hideOnNoData={false}
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                startTime={startTime}
                endTime={endTime}
                metricSpecifiers={[
                    {
                        metricType: MetricType.Metric,
                        metricName: "container_net_tcp_active_connections",
                        splits: groupByToUse,
                        filters: filters,
                        excludeFilters: props.excludeFilters,
                        functions: [],
                        aggregation: "sum",
                        visualization: {
                            lineColor: "#4bc0c0",
                            lineDotColor: "#4bc0c033",
                            displayName:  "Total Open TCP Connections",
                        }
                    },
                ]}
                type={ChartType.Line}
                title={`Open TCP Connections - ` + formatServiceNameFilterValues(props.serviceName!)}
                styling={{borderless: true}}
            />
            <MultiMetoroMetricsChart
                hideOnNoData={false}
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                startTime={startTime}
                endTime={endTime}
                metricSpecifiers={[
                    {
                        metricType: MetricType.Metric,
                        metricName: "container_net_tcp_active_connections",
                        splits: groupByToUse,
                        filters: filters,
                        excludeFilters: props.excludeFilters,
                        functions: [],
                        aggregation: "min",
                        visualization: {
                            lineColor: "#4bc0c0",
                            lineDotColor: "#4bc0c033",
                            displayName:  "Min Open TCP Connections",
                        }
                    },
                    {
                        metricType: MetricType.Metric,
                        metricName: "container_net_tcp_active_connections",
                        splits: groupByToUse,
                        filters: filters,
                        excludeFilters: props.excludeFilters,
                        functions: [],
                        aggregation: "max",
                        visualization: {
                            lineColor: "#3793ff",
                            lineDotColor: "#3793ff33",
                            displayName:  "Max Open TCP Connections",
                        }
                    },
                    {
                        metricType: MetricType.Metric,
                        metricName: "container_net_tcp_active_connections",
                        splits: groupByToUse,
                        filters: filters,
                        excludeFilters: props.excludeFilters,
                        functions: [],
                        aggregation: "avg",
                        visualization: {
                            lineColor: "#ffce56",
                            lineDotColor: "#ffce5633",
                            displayName:  "Avg Open TCP Connections",
                        }
                    },
                ]}
                type={ChartType.Line}
                title={`Open TCP Connections - Pods`}
                styling={{borderless: true}}
            />
            <MultiMetoroMetricsChart
                hideOnNoData={false}
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                startTime={startTime}
                endTime={endTime}
                metricSpecifiers={[
                    {
                        metricType: MetricType.Metric,
                        metricName: "container_resources_disk_read_bytes_total",
                        splits: groupByToUse,
                        filters: filters,
                        excludeFilters: props.excludeFilters,
                        functions: [],
                        aggregation: "min",
                        visualization: {
                            lineColor: "#4bc0c0",
                            lineDotColor: "#4bc0c033",
                            displayName:  "Min Disk Bytes Read",
                        }
                    },
                    {
                        metricType: MetricType.Metric,
                        metricName: "container_resources_disk_read_bytes_total",
                        splits: groupByToUse,
                        filters: filters,
                        excludeFilters: props.excludeFilters,
                        functions: [],
                        aggregation: "max",
                        visualization: {
                            lineColor: "#3793ff",
                            lineDotColor: "#3793ff33",
                            displayName:  "Max Disk Bytes Read",
                        }
                    },
                    {
                        metricType: MetricType.Metric,
                        metricName: "container_resources_disk_read_bytes_total",
                        splits: groupByToUse,
                        filters: filters,
                        excludeFilters: props.excludeFilters,
                        functions: [],
                        aggregation: "avg",
                        visualization: {
                            lineColor: "#ffce56",
                            lineDotColor: "#ffce5633",
                            displayName:  "Avg Disk Bytes Read",
                        }
                    },
                ]}
                type={ChartType.Line}
                title={`Disk Bytes Read`}
                styling={{borderless: true}}
            />
            <MultiMetoroMetricsChart
                hideOnNoData={false}
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                startTime={startTime}
                endTime={endTime}
                metricSpecifiers={[
                    {
                        metricType: MetricType.Metric,
                        metricName: "container_resources_disk_written_bytes_total",
                        splits: groupByToUse,
                        filters: filters,
                        excludeFilters: props.excludeFilters,
                        functions: [],
                        aggregation: "min",
                        visualization: {
                            lineColor: "#4bc0c0",
                            lineDotColor: "#4bc0c033",
                            displayName:  "Min Disk Bytes Written",
                        }
                    },
                    {
                        metricType: MetricType.Metric,
                        metricName: "container_resources_disk_written_bytes_total",
                        splits: groupByToUse,
                        filters: filters,
                        excludeFilters: props.excludeFilters,
                        functions: [],
                        aggregation: "max",
                        visualization: {
                            lineColor: "#3793ff",
                            lineDotColor: "#3793ff33",
                            displayName:  "Max Disk Bytes Written",
                        }
                    },
                    {
                        metricType: MetricType.Metric,
                        metricName: "container_resources_disk_written_bytes_total",
                        splits: groupByToUse,
                        filters: filters,
                        excludeFilters: props.excludeFilters,
                        functions: [],
                        aggregation: "avg",
                        visualization: {
                            lineColor: "#ffce56",
                            lineDotColor: "#ffce5633",
                            displayName:  "Avg Disk Bytes Written",
                        }
                    },
                ]}
                type={ChartType.Line}
                title={`Disk Bytes Written`}
                styling={{borderless: true}}
            />
        </div>}
        {groupByToUse.length > 0 &&
            <div className={"grid-cols-2 grid gap-4 rounded text-textlight overflow-y-auto relative"}>
                <MultiMetoroMetricsChart
                    hideOnNoData={false}
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    startTime={startTime}
                    endTime={endTime}
                    metricSpecifiers={[
                        {
                            metricType: MetricType.Metric,
                            metricName: "container_resources_cpu_usage_seconds_total",
                            splits: groupByToUse,
                            filters: filters,
                            excludeFilters: props.excludeFilters,
                            functions: [{
                                id: "1",
                                functionType: RateFunctionType.PerSecond
                            }],
                            aggregation: "max",
                            visualization: {
                                displayName:  "CPU Usage",
                            }
                        },
                        {
                            metricType: MetricType.Metric,
                            metricName: "container_resources_cpu_limit_cores",
                            splits: [],
                            filters: filters,
                            excludeFilters: props.excludeFilters,
                            functions: [],
                            aggregation: "max",
                            visualization: {
                                displayName: "CPU Limit",
                                lineDash: [10, 10],
                                lineColor: "#ff6384",
                                lineDotColor: "#ff638400",
                                lineDotSize: 0
                            }
                        }
                    ]}
                    type={ChartType.Line}
                    title={`CPU Usage`}
                    styling={{borderless: true}}
                />
                <MultiMetoroMetricsChart
                    hideOnNoData={false}
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    startTime={startTime}
                    endTime={endTime}
                    metricSpecifiers={[
                        {
                            metricType: MetricType.Metric,
                            metricName: "container_resources_memory_rss_bytes",
                            splits: groupByToUse,
                            filters: filters,
                            excludeFilters: props.excludeFilters,
                            functions: [],
                            aggregation: "sum",
                            visualization: {
                                displayName:  "Memory Usage",
                            }
                        },
                        {
                            metricType: MetricType.Metric,
                            metricName: "container_resources_memory_limit_bytes",
                            splits: [],
                            filters: filters,
                            excludeFilters: props.excludeFilters,
                            functions: [],
                            aggregation: "max",
                            visualization: {
                                displayName: "Memory Limit",
                                lineDash: [10, 10],
                                lineColor: "#ff6384",
                                lineDotColor: "#ff638400",
                                lineDotSize: 0
                            }
                        }

                    ]}
                    type={ChartType.Line}
                    title={`Memory Usage`}
                    styling={{borderless: true}}
                />
                <MultiMetoroMetricsChart
                    hideOnNoData={false}
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    startTime={startTime}
                    endTime={endTime}
                    metricSpecifiers={[
                        {
                            metricType: MetricType.Metric,
                            metricName: "container_net_bytes_sent_total",
                            splits: networkSplits,
                            filters: filters,
                            excludeFilters: props.excludeFilters,
                            functions: [],
                            aggregation: "sum",
                            visualization: {
                                displayName:  "Network Bytes Sent",
                            }
                        },
                    ]}
                    type={ChartType.Line}
                    title={`Network Bytes Sent`}
                    styling={{borderless: true}}
                />

                <MultiMetoroMetricsChart
                    hideOnNoData={false}
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    startTime={startTime}
                    endTime={endTime}
                    metricSpecifiers={[
                        {
                            metricType: MetricType.Metric,
                            metricName: "container_net_bytes_received_total",
                            splits: networkSplits,
                            filters: filters,
                            excludeFilters: props.excludeFilters,
                            functions: [],
                            aggregation: "sum",
                            visualization: {
                                displayName:  "Network Bytes Received",
                            }
                        },
                    ]}
                    type={ChartType.Line}
                    title={`Network Bytes Received`}
                    styling={{borderless: true}}
                />
                <MultiMetoroMetricsChart
                    hideOnNoData={false}
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    startTime={startTime}
                    endTime={endTime}
                    metricSpecifiers={[
                        {
                            metricType: MetricType.Metric,
                            metricName: "container_net_tcp_active_connections",
                            splits: groupByToUse,
                            filters: filters,
                            excludeFilters: props.excludeFilters,
                            functions: [],
                            aggregation: "sum",
                            visualization: {
                                displayName:  "Open TCP Connections",
                            }
                        },
                    ]}
                    type={ChartType.Line}
                    title={`Open TCP Connections`}
                    styling={{borderless: true}}
                />
            </div>
        }
    </div>
}

function updatePodsCount(timeRange: TimeRange, props: {
                             serviceName: string
                         }, environments: string[], setPodLifecycle: (value: (((prevState: (PodSummary[] | undefined)) => (PodSummary[] | undefined)) | PodSummary[] | undefined)) => void,
                         abortController: AbortController,
                         setAbortController: (value: React.SetStateAction<AbortController>) => void
) {
    try {
        abortController.abort("Subsequent request made");
        let newAbortController = new AbortController();
        setAbortController(newAbortController);
        const startEnd = timeRange.getStartEnd();
        axios.post("/api/v1/k8s/pods/count", {
                "serviceName": props.serviceName,
                "startTime": Math.floor(startEnd[0].getTime() / 1000),
                "endTime": Math.floor(startEnd[1].getTime() / 1000),
                "environments": environments
            },
            {
                signal: newAbortController.signal
            }
        ).then((response) => {
            const respData = response.data as GetPodsCountResponse
            setPodLifecycle(respData.summaries);
        }).catch((e) => {
            console.error(e);
        })
    } catch (e) {
        console.error(e);
    }
}

export async function getPods(timeRange: TimeRange,
                              environments: string[],
                              setPods: (value: (((prevState: GetPodsResponse | undefined) => GetPodsResponse) | GetPodsResponse | undefined)) => void,
                              abortController: AbortController,
                              setAbortController: (value: React.SetStateAction<AbortController>) => void,
                              serviceName: string,
                              nodeName: string,
) {
    try {
        abortController.abort("Subsequent request made");
        let newAbortController = new AbortController();
        setAbortController(newAbortController);
        const startEnd = timeRange.getStartEnd();
        const response = await axios.post("/api/v1/k8s/pods", {
                "serviceName": serviceName,
                "nodeName": nodeName,
                "startTime": Math.floor(startEnd[0].getTime() / 1000),
                "endTime": Math.floor(startEnd[1].getTime() / 1000),
                "environments": environments
            },
            {
                signal: newAbortController.signal
            }
        )
        const responseData = response.data as GetPodsResponse;
        setPods(responseData);
    } catch (e) {
        console.error(e);
    }
}

async function updateK8sSummaryRequest(environments: string[], timeRange: TimeRange, props: {
                                           serviceName: string
                                       }, setK8sSummaryResponse: (value: (((prevState: K8sSummaryResponseV2 | undefined) => K8sSummaryResponseV2) | K8sSummaryResponseV2 | undefined)) => void,
                                       abortController: AbortController,
                                       setAbortController: (value: React.SetStateAction<AbortController>) => void
) {
    try {
        abortController.abort("Subsequent request made");
        let newAbortController = new AbortController();
        setAbortController(newAbortController);
        const startEnd = timeRange.getStartEnd();
        const response = await axios.post("/api/v1/k8s/summary", {
                "serviceName": props.serviceName,
                "startTime": Math.floor(startEnd[0].getTime() / 1000),
                "endTime": Math.floor(startEnd[1].getTime() / 1000),
                "environments": environments
            },
            {
                signal: newAbortController.signal
            }
        )
        const responseData = response.data as K8sSummaryResponseV2;
        setK8sSummaryResponse(responseData);
    } catch (e) {
        console.error(e);
    }
}

function PodCounts(props: { podCounts: PodSummary[] }) {
    return <div className={"mt-8 w-full h-[100px] bg-background-secondary"}>
        <div className={"flex gap-1 w-full"}>
            {props.podCounts.map((podCount, index) => {
                return <Tooltip delayDuration={0}>
                    <TooltipTrigger className={"grow"}>
                        <div
                            className={cn("grow h-[48px] hover:bg-secondarytransparent", getColour(podCount), getHoverColor(podCount))}/>
                    </TooltipTrigger>
                    <TooltipContent side={"bottom"}>
                        <div className={"flex flex-col gap-y-1"}>
                            <div
                                className={"font-semibold"}>{new Date(podCount.bucket * 1000).toLocaleString()}</div>
                            {podCount.running > 0 &&
                                <div className={"flex gap-1 items-center"}>
                                    <div className={"h-[11px] w-[11px] bg-secondary"}></div>
                                    <div>Pods Running:</div>
                                    <div className={"font-semibold text-xs"}>{podCount.running}</div>
                                </div>
                            }
                            {podCount.waiting > 0 &&
                                <div className={"flex gap-1 items-center"}>
                                    <div className={"h-[11px] w-[11px] bg-amber-500"}></div>
                                    <div>Pods Waiting:</div>
                                    <div className={"font-semibold text-xs"}>{podCount.waiting}</div>
                                </div>
                            }
                            {
                                podCount.terminated > 0 &&
                                <div className={"flex gap-1 items-center"}>
                                    <div className={"h-[11px] w-[11px] bg-red-500"}></div>
                                    <div>Pods Terminated:</div>
                                    <div className={"font-semibold text-xs"}>{podCount.terminated}</div>
                                </div>
                            }
                        </div>
                    </TooltipContent>
                </Tooltip>
            })}
        </div>
    </div>
}

function getColour(podCount: PodSummary) {
    if (podCount.terminated > 0) {
        return "bg-red-500"
    } else if (podCount.waiting > 0) {
        return "bg-amber-500"
    } else if (podCount.running > 0) {
        return "bg-secondary"
    }
    return "bg-secondary"
}

function getHoverColor(podCount: PodSummary) {
    if (podCount.terminated > 0) {
        return "hover:bg-gray-600"
    } else if (podCount.waiting > 0) {
        return "hover:bg-amber-600"
    } else if (podCount.running > 0) {
        return "hover:bg-secondarytransparent"
    }
    return "hover:bg-gray-800"
}

export const KubernetesView: React.FC<KubernetesViewProps> = (props: {
    serviceName: string,
}) => {
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const [searchParams, setSearchParams] = useSearchParams();
    const [environments, setEnvironments] = useState<string[]>([]);
    const [allEnvironments, setAllEnvironments] = useState<string[]>([]);
    const [filtersForMetrics, setFiltersForMetrics] = useState<Map<string, string[]>>(new Map<string, string[]>());

    // For the pods table
    const [currentRunningPods, setCurrentRunningPods] = React.useState<GetPodsResponse>();
    const debouncedGetPods = useDebouncedCallback(getPods, 10);
    const [getPodsAbortController, setGetPodsAbortController] = useState(new AbortController());


    // For the Pod Events section.
    const [podSummaryCounts, setPodSummaryCounts] = React.useState<PodSummary[]>();
    const debouncedUpdatePodsSummaryCounts = useDebouncedCallback(updatePodsCount, 10);
    const [podSummaryAbortController, setPodSummaryAbortController] = useState(new AbortController());

    // For the summary table
    const [k8sSummary, setK8sSummary] = useState<K8sSummaryResponseV2>();
    const debouncedUpdateK8sSummaryRequest = useDebouncedCallback(updateK8sSummaryRequest, 10);
    const [k8sSummaryAbortController, setK8sSummaryAbortController] = useState(new AbortController());

    useEffect(() => {
        axios.get("/api/v1/environments").then((response) => {
            setAllEnvironments(response.data.environments);
        })
    }, [])

    useEffect(() => {
        let environment = searchParams.get("environment") || "";
        if (JSON.stringify(environments) === JSON.stringify([environments]) || (environment === "" && environments.length === 0)) {
            return
        }
        if (environment !== "") {
            if (environments.length === 1 && environments[0] === environment) {
                return
            }
            setEnvironments([environment])
        } else {
            setEnvironments([])
        }
    }, [searchParams, allEnvironments])

    useEffect(() => {
        let filtersToSet = filtersForMetrics;
        filtersToSet.set("environment", environments);
        setFiltersForMetrics(filtersToSet)
    }, [environments]);

    useEffect(() => {
        debouncedGetPods(timeRange, environments, setCurrentRunningPods, getPodsAbortController, setGetPodsAbortController, props.serviceName, "");
    }, [timeRange, environments, props.serviceName]);


    useEffect(() => {
        debouncedUpdateK8sSummaryRequest(environments, timeRange, props, setK8sSummary, k8sSummaryAbortController, setK8sSummaryAbortController);
    }, [timeRange, environments, props.serviceName]);


    useEffect(() => {
        debouncedUpdatePodsSummaryCounts(timeRange, props, environments, setPodSummaryCounts, podSummaryAbortController, setPodSummaryAbortController);
    }, [timeRange, environments, props.serviceName]);
    return <div className={"flex flex-col grow shrink gap-4 min-w-0 min-h-0 overflow-y-auto"}>
        <div className={"grid grid-cols-2 grid-rows-1 h-[256px] gap-x-4 flex-none"}>
            <SummaryTable summary={k8sSummary}
                          pods={currentRunningPods}/>
            <div className={"text-textmedium relative bg-backgroundmedium overflow-y-auto"}>
                <MultiMetoroMetricsChart
                    hideOnNoData={false}
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    startTime={Math.floor((timeRange.getStartEnd())[0].getTime() / 1000)}
                    endTime={Math.floor((timeRange.getStartEnd())[1].getTime() / 1000)}
                    metricSpecifiers={[
                        {
                            metricType: MetricType.Kubernetes,
                            metricName: "HPA Min Replicas",
                            splits: [],
                            filters: new Map(Object.entries({
                                "ServiceName": [props.serviceName],
                                "Kind": ["HorizontalPodAutoscaler"],
                                "Environment": environments
                            })) as Map<string, string[]>,
                            excludeFilters: new Map(Object.entries({

                            })),
                            functions: [],
                            aggregation: "max",
                            visualization: {
                                displayName:  "HPA Min Replicas",
                            },
                            jsonPath: "spec.minReplicas"
                        },
                        {
                            metricType: MetricType.Kubernetes,
                            metricName: "HPA Max Replicas",
                            splits: [],
                            filters: new Map(Object.entries({
                                "ServiceName": [props.serviceName],
                                "Kind": ["HorizontalPodAutoscaler"],
                                "Environment": environments
                            })) as Map<string, string[]>,
                            excludeFilters: new Map(Object.entries({

                            })),
                            functions: [],
                            aggregation: "max",
                            visualization: {
                                displayName:  "HPA Max Replicas",
                            },
                            jsonPath: "spec.maxReplicas"
                        },
                        {
                            metricType: MetricType.Kubernetes,
                            metricName: "Pods -",
                            splits: ["pod_phase"],
                            filters: new Map(Object.entries({
                                "ServiceName": [props.serviceName],
                                "Kind": ["Pod"],
                                "Environment": environments
                            })) as Map<string, string[]>,
                            excludeFilters: new Map(Object.entries({

                            })),

                            functions: [],
                            aggregation: "count",
                            visualization: {
                                displayName:  "Pods -",
                            },
                        },
                        {
                            metricType: MetricType.Kubernetes,
                            metricName: "HPA Desired Replicas",
                            splits: [],
                            filters: new Map(Object.entries({
                                "ServiceName": [props.serviceName],
                                "Kind": ["HorizontalPodAutoscaler"],
                                "Environment": environments
                            })) as Map<string, string[]>,
                            excludeFilters: new Map(Object.entries({

                            })),
                            functions: [],
                            aggregation: "max",
                            visualization: {
                                displayName:  "HPA Desired Replicas",
                            },
                            jsonPath: "status.desiredReplicas"
                        }
                    ]}
                    type={ChartType.Line}
                    title={`Pod Status`}
                    styling={{borderless: true}}
                />

            </div>
        </div>
        <div className={""}>
            <PodsTable podResponse={currentRunningPods} serviceName={props.serviceName}/>
        </div>
        <MetricsPanel
            filters={filtersForMetrics}
            serviceName={props.serviceName}
            title={"Aggregated Pod Metrics"}
            tooltipText={"This panel shows aggregated metrics for all containers in the selected service. For more detailed\n" +
                "                information, drill into individual pods."}
        />
    </div>

}
