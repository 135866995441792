import React, {useEffect, useState} from "react";
import {cn, usePreserveQueryParamsNavigate} from "../components/ui/lib/utils";
import {
    AudioLinesIcon,
    BellIcon,
    BookIcon,
    Boxes,
    DollarSignIcon,
    GlobeIcon,
    HomeIcon,
    Info,
    LogOutIcon,
    RadarIcon,
    ShieldAlertIcon,
    Telescope
} from "lucide-react";
import {DateTimePickerRange} from "../components/ui/DateTimeSelector";
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "../components/ui/tooltip";
import useAuth from "../hooks/useAuth";
import {TbLayoutDashboard} from "react-icons/tb";
import {IoMdSettings} from "react-icons/io";
import logoIcon from 'assets/images/logoColour.png';
import {FaMapMarked} from "react-icons/fa";
import {ClusterSelector} from "../components/ClusterSelector";
import {ExpandedChartDialog} from "../components/ExpandedChartDialog";
import {getCurrentProductFromApi} from "./Billing";
import {useCookies} from "react-cookie";
import {DropDownItem} from "../components/Input/MultiSelectorDropdown/MultiSelectorDropDown";
import axios from "../utility/customAxios";
import {formatServiceNameFilterValues, RemoveK8s} from "../components/Filter/Filter";
import {SingleSelectorSearchableDropdown} from "../components/Input/SingleSelectorSearchableDropdown";
import {Toaster} from "../components/ui/toaster";
import {K8sPodDetails} from "../components/K8sInfo/model";
import {WarningBanner, WarningBannerType} from "../components/WarningBanner/WarningBanner";

const BaseViewTest = () => {
    return <BaseView title={"Services"}/>;
}

function IconWithBorder(props: { highlighted?: boolean, icon: React.ReactNode }) {
    return (
        <div
            className={cn("hover:cursor-pointer hover:border-primary h-8 w-8 border rounded justify-center flex items-center text-primary stroke-2 stroke-primary", props.highlighted ? "border-secondary bg-secondarytransparenter text-secondary stroke-secondary" : "")}>
            {props.icon}
        </div>
    )
}

function CornerSquare() {
    const navigate = usePreserveQueryParamsNavigate()
    return (
        <a className={"hover:cursor-pointer"} onClick={
            () => {
                navigate("/home")
            }
        }>
            <div className={"h-12 w-12 border-b justify-center items-center flex"}>
                <img src={logoIcon} alt="Metoro" className="w-8 h-8"/>
            </div>
        </a>
    )
}

function LeftBar() {
    const location = useLocation();
    const navigate = usePreserveQueryParamsNavigate();
    const jwtContext = useAuth();
    const {search} = useLocation();


    const icons = [
        {
            "tooltip": "Home",
            "location": "/home",
            "pageNames": ["/home"],
            "icon": <HomeIcon className={"w-4 h-4"}/>
        },
        {
            "tooltip": "Dashboards",
            "location": "/dashboards",
            "pageNames": ["/dashboard", "/dashboards"],
            "icon": <TbLayoutDashboard className={"w-4 h-4"}/>
        },
        {
            "tooltip": "Alerts",
            "location": "/alerts",
            "pageNames": ["/alerts", "/new-alert", "/alert"],
            "icon": <BellIcon className={"w-4 h-4"}/>
        },
        {
            "tooltip": "Issues",
            "pageNames": ["/issues"],
            "location": "/issues",
            "icon": <RadarIcon className={"w-4 h-4"}/>
        },
        {
            "tooltip": "Logs",
            "pageNames": ["/logs"],
            "location": "/logs",
            "icon": <BookIcon className={"w-4 h-4"}/>
        },
        {
            "tooltip": "Traces",
            "pageNames": ["/traces"],
            "location": "/traces",
            "icon": <AudioLinesIcon className={"w-4 h-4"}/>
        },
        {
            "tooltip": "Services",
            "location": "/service-catalog",
            "pageNames": ["/service-catalog", "/service"],
            "icon": <GlobeIcon className={"w-4 h-4"}/>
        },
        {
            "tooltip": "Service Map",
            "location": "/service-map",
            pageNames: ["/service-map"],
            icon: <FaMapMarked className={"w-4 h-4"}/>
        },
        {
            "tooltip": "Infrastructure",
            "location": "/infrastructure",
            pageNames: ["/infrastructure"],
            icon: <Boxes className={"w-4 h-4"}/>
        },
        {
            "tooltip": "K8s Events",
            "location": "/k8s-events",
            pageNames: ["/k8s-events"],
            icon: <ShieldAlertIcon className={"w-5 h-5"}/>
        },
        {
            "tooltip": "Metric Explorer",
            "location": "/metric-explorer",
            pageNames: ["/metric-explorer"],
            icon: <Telescope className={"w-4 h-4"}/>
        },
        {
            "tooltip": "Billing",
            "location": "/billing",
            pageNames: ["/billing"],
            icon: <DollarSignIcon className={"w-4 h-4"}/>
        },
        {
            "tooltip": "Settings",
            "location": "/settings",
            pageNames: ["/settings"],
            icon: <IoMdSettings className={"w-4 h-4"}/>
        }
    ]

    const openInNewTab = (url: string): void => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const generatePreservedPath = (path: string, searchParams: URLSearchParams) => {
        const split = path.split("?");
        const basePath = split[0];
        const newSearchParams = split.length > 1 ? new URLSearchParams(split[1]) : new URLSearchParams();

        const defaultTimeRange = localStorage.getItem("defaultTimeRange")

        if (defaultTimeRange && defaultTimeRange !== "" && defaultTimeRange !== "not-set") {
            newSearchParams.set("last", defaultTimeRange)
        } else {
            // Preserve the "last" parameter if it exists
            const last = searchParams.get("last");
            if (last) {
                newSearchParams.set("last", last);
            }
        }

        // Preserve the environment parameter if it exists
        const environment = searchParams.get("environment");
        if (environment) {
            newSearchParams.set("environment", environment);
        }

        return `${basePath}?${newSearchParams.toString()}`;
    };

    const [searchParams] = useSearchParams();


    return (
        <div className={"w-12 border-r bg-backgroundmedium"}>
            <CornerSquare/>
            <div className={"pt-2 w-12"}>
                {icons.map((icon, index) => (
                    <Link
                        key={index}
                        to={generatePreservedPath(icon.location, searchParams)}
                        onClick={(event) => {
                            if (event.metaKey || event.ctrlKey) {
                                event.preventDefault(); // Prevent default to allow new tab
                                openInNewTab(icon.location);
                            }
                        }}
                        onAuxClick={(event) => {
                            if (event.button === 1) { // middle mouse button
                                event.preventDefault();
                                openInNewTab(icon.location);
                            }
                        }}
                        className="h-10 w-12 justify-center items-center flex"
                    >
                        <Tooltip delayDuration={10}>
                            <TooltipTrigger>
                                <IconWithBorder highlighted={icon.pageNames.includes(location.pathname)} key={index}
                                                icon={icon.icon}/>
                            </TooltipTrigger>
                            <TooltipContent side={"right"}
                                            className={"bg-backgroundlight border text-textlight rounded"}>
                                {icon.tooltip}
                            </TooltipContent>
                        </Tooltip>
                    </Link>
                ))}
                {/*Treat logout as a separete case as we just logout, so no navigation.*/}
                <div className={"h-10 w-12 justify-center items-center flex"} onClick={() => {
                    jwtContext.logout()
                    window.location.href = "/login"
                }}>
                    <Tooltip delayDuration={10}>
                        <TooltipTrigger>
                            <IconWithBorder icon={<LogOutIcon className={"w-4 h-4"}
                            />}/>
                        </TooltipTrigger>
                        <TooltipContent side={"right"} className={"bg-backgroundlight border text-textlight rounded"}>
                            Logout 👋
                        </TooltipContent>
                    </Tooltip>
                </div>
            </div>
        </div>
    )

}

interface TopBarProps {
    releaseStage?: string;
    children?: React.ReactNode;
    title: string;
    disableTimeRange?: boolean;
    disableClusterSelector?: boolean;
    enableServiceDropdown?: boolean;
}

interface Services {
    services: Service[]
}

interface Service {
    serviceName: string
}


interface MetoroNodeAgentsHealthResponse {
    status: string
    pods: K8sPodDetails[]
}

// Cache object to store health check results
const healthCheckCache = {
    lastChecked: 0,
    data: null,
};

const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

function TopBar(props: TopBarProps) {
    const [trialEnd, setTrialEnd] = useState<number>();
    const [cookies, setCookie] = useCookies(['trialEnd']);
    const [searchParams, setSearchParams] = useSearchParams();
    const [services, setServices] = useState<Service[]>([]);
    const [selectedService, setSelectedService] = useState<DropDownItem>(setInitalValue());
    const [metoroNodeAgentsStatus, setMetoroNodeAgentsStatus] = useState<MetoroNodeAgentsHealthResponse>();

    function setInitalValue() {
        return {
            displayName: formatServiceNameFilterValues(searchParams.get('service') || ''),
            value: searchParams.get('service') || ''
        }
    }

    useEffect(() => {
        const fetchTrialEndData = async () => {
            if (!cookies.trialEnd) {

                // If running locally, do not fetch data from API
                if (process.env.REACT_APP_API_URL !== undefined && process.env.REACT_APP_API_URL.includes("localhost")) {
                    return;
                }
                // If cookie does not exist, fetch data from API
                const response = await getCurrentProductFromApi();

                // Set cookie for 10 minutes (600 seconds)
                setCookie('trialEnd', response.data.currentProductTrialEnd, {path: '/', maxAge: 600});
            }
        };

        fetchTrialEndData();
    }, []);

    useEffect(() => {
        const getTrialEndFromCookie = () => {
            if (cookies.trialEnd) {
                // If cookie exists, set state
                setTrialEnd(cookies.trialEnd);
            }
        };

        getTrialEndFromCookie();

    }, [cookies]);

    useEffect(() => {
        const checkMetoroNodeAgentsHealth = async () => {
            const now = Date.now();

            // Return cached data if it's less than 5 minutes old
            if (healthCheckCache.data && now - healthCheckCache.lastChecked < CACHE_DURATION) {
                setMetoroNodeAgentsStatus(healthCheckCache.data);
                return;
            }

            try {
                await axios.get('/api/v1/metoro/nodeAgents/status').then((response) => {
                    healthCheckCache.data = response.data;
                    healthCheckCache.lastChecked = now;
                    setMetoroNodeAgentsStatus(response.data);
                });
            } catch (e) {
                console.error(e);
            }
        }

        checkMetoroNodeAgentsHealth();
    }, []);

    function getDaysRemaining(trialEnd: number) {
        const today = new Date();
        const diff = new Date(trialEnd).getTime() - today.getTime();
        return Math.ceil(diff / (1000 * 60 * 60 * 24));
    }

    useEffect(() => {
        if (props.enableServiceDropdown) {
            // Fetch services from API
            axios.get('/api/v1/services').then((response) => {
                setServices(response.data.services);
            }).catch((e) => {
                console.error(e);
            });
        }
    }, []);

    useEffect(() => {
        if (selectedService !== undefined) {
            const service = selectedService.value;
            setSearchParams(prev => {
                let existing = new URLSearchParams(window.location.search)
                existing.set("service", service)
                return existing
            })
        }

        setSearchParams(prev => {
            let existing = new URLSearchParams(window.location.search)
            existing.delete("fullscreenChart")
            return existing
        })

    }, [selectedService]);

    let possibleItems = [] as DropDownItem[];
    if (services && services.length > 0) {
        const formattedServiceNames = services.map((service) => {
            return formatServiceNameFilterValues(service.serviceName);
        })
        // find the duplicates in formattedServiceNames
        const formattedServiceNamesSet = new Set(formattedServiceNames);
        if (formattedServiceNamesSet.size != formattedServiceNames.length) {
            services.forEach((service) => {
                possibleItems.push({displayName: RemoveK8s(service.serviceName), value: service.serviceName});
            });
        } else {
            services.forEach((service) => {
                possibleItems.push({
                    displayName: formatServiceNameFilterValues(service.serviceName),
                    value: service.serviceName
                });
            });
        }
    }


    return (
        <div className={"h-12 w-full border-b bg-backgroundmedium flex justify-between px-4 items-center"}>
            <div className={"text-textlight text-2xl font-semibold leading-loose truncate"}>
                <div className={"flex gap-4 items-center"}>
                    {!props.enableServiceDropdown && <div>{props.title}</div>}
                    {props.enableServiceDropdown &&
                        <div className={"text-base"}>
                            <SingleSelectorSearchableDropdown
                                searchPlaceholder={"Search services..."}
                                noMatchString={"No services found"}
                                possibleItems={possibleItems}
                                selected={selectedService}
                                setSelected={setSelectedService}
                                displaySettings={
                                    {
                                        smallSize: false,
                                        borderClassName: "border-r",
                                    }
                                }
                            />
                        </div>}
                    {
                        props.releaseStage &&
                        <div
                            className={"flex text-sm bg-purple-400/10 border border-purple-400 px-2 py-1"}>{props.releaseStage}
                            {/*    Info tooltip*/}
                            <div className={"flex flex-col items-center justify-center"}>
                                <Tooltip>
                                    <TooltipTrigger>
                                        <Info className={"w-4 h-4 ml-2"}/>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        This feature is currently in {props.releaseStage}. Please report any
                                        issues to feedback@metoro.io.
                                    </TooltipContent>
                                </Tooltip>
                            </div>
                        </div>
                    }
                    {trialEnd !== undefined && trialEnd !== 0 && getDaysRemaining(trialEnd * 1000).toString() !== NaN.toString() &&
                        <WarningBanner type={WarningBannerType.trialEndBanner}
                                       message={getDaysRemaining(trialEnd * 1000) + " days remaining in free trial"}
                                       details={"Please go to the billing page to upgrade your account if you would like to use Metoro after the trial period"}
                        />}
                    {metoroNodeAgentsStatus?.status === "unhealthy" &&
                        <WarningBanner type={WarningBannerType.nodeAgentHealth}
                                       message={"Some node agents aren't running"}
                                       pods={metoroNodeAgentsStatus.pods}
                        />}
                </div>
            </div>
            <div className={"flex gap-x-4"}>
                {!props.disableClusterSelector && <ClusterSelector/>}
                {!props.disableTimeRange && <DateTimePickerRange/>}
            </div>
        </div>
    )
}

interface ContentProps {
    children?: React.ReactNode;
}

function Content(props: ContentProps) {
    return (
        <div className={"flex min-h-0 min-w-0 grow"}>
            {props.children}
        </div>
    )
}

interface MainContentProps {
    releaseStage?: string;
    title: string;
    children?: React.ReactNode;
    disableTimeRange?: boolean;
    disableClusterSelector?: boolean;
    enableServiceDropdown?: boolean;
}

function MainContent(props: MainContentProps) {
    return (
        <div className={"min-w-0 min-h-0 flex flex-col grow shrink"}>
            <TopBar
                releaseStage={props.releaseStage}
                disableTimeRange={props.disableTimeRange}
                title={props.title}
                disableClusterSelector={props.disableClusterSelector}
                enableServiceDropdown={props.enableServiceDropdown}
            />
            <Content>
                {props.children}
            </Content>
            <Toaster/>
        </div>
    )
}

interface BaseViewProps {
    releaseStage?: string;
    disableTimeRange?: boolean;
    className?: string;
    title: string;
    children?: React.ReactNode;
    disableClusterSelector?: boolean;
    enableServiceDropdown?: boolean;
}

function BaseView(props: BaseViewProps) {
    return (
        <div className={cn("w-screen h-screen flex text-textmedium", props.className)}>
            <TooltipProvider>
                <LeftBar/>
                <ExpandedChartDialog/>
                <MainContent
                    releaseStage={props.releaseStage}
                    enableServiceDropdown={props.enableServiceDropdown}
                    disableTimeRange={props.disableTimeRange} title={props.title}
                    disableClusterSelector={props.disableClusterSelector}>
                    {props.children}
                </MainContent>
            </TooltipProvider>
        </div>
    );
}

export {
    BaseViewTest,
    BaseView
}